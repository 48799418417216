import { getAuth } from "firebase/auth";
import db from "./firebase-db.js";
import {
    collection,
    addDoc,
    getDocs,
    doc,
    getDoc,
    setDoc,
    deleteDoc,
    query,
    where
} from "firebase/firestore";

// Generic function to create a document in any collection
export const createDocument = async (collectionName, data) => {
    try {
        const docRef = await addDoc(collection(db, collectionName), data);
        console.log("Document written with ID: ", docRef.id);
        return docRef.id;
    } catch (e) {
        console.error("Error adding document: ", e);
    }
};

export const createDocumentWithId = async (collectionName, id, data) => {
    try {
        console.log("Creating document with ID: ", id);
        await setDoc(doc(db, collectionName, id), data);
        console.log("Document written with ID: ", id);
        return id;
    } catch (e) {
        console.error("Error adding document: ", e);
    }
};

// Generic function to get all documents from any collection
export const getDocuments = async (collectionName) => {
    const querySnapshot = await getDocs(collection(db, collectionName));
    let documents = [];
    console.log(
        querySnapshot.metadata.fromCache ? "From cache" : "From server"
    );
    querySnapshot.forEach((doc) => {
        documents.push({ id: doc.id, ...doc.data() });
    });
    return documents;
};

// Generic function to get a specific document from any collection
export const getDocument = async (collectionName, id) => {
    const docRef = doc(db, collectionName, id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return { id: docSnap.id, ...docSnap.data() };
    } else {
        console.log("No such document!");
    }
};

// Generic function to update a document in any collection
export const updateDocument = async (collectionName, id, data) => {
    try {
        await setDoc(doc(db, collectionName, id), {
            ...data,
            updated: {
                timestamp: new Date().toISOString(),
                userId: getAuth().currentUser.uid,
                userFullName: getAuth().currentUser.displayName
            }
        });
        console.log("Document successfully updated!");
    } catch (e) {
        console.error("Error updating document: ", e);
    }
};

// Generic function to delete a document from any collection
export const deleteDocument = async (collectionName, id) => {
    try {
        await deleteDoc(doc(db, collectionName, id));
        console.log("Document successfully deleted!");
    } catch (e) {
        console.error("Error removing document: ", e);
    }
};

// Add a new document to a subcollection
export async function createSubcollectionDocument(
    collectionName,
    docId,
    subcollectionName,
    data
) {
    try {
        const subcollectionRef = collection(
            db,
            `${collectionName}/${docId}/${subcollectionName}`
        );
        const docRef = await addDoc(subcollectionRef, data);
        return docRef.id;
    } catch (e) {
        console.error("Error adding document to subcollection: ", e);
        throw e;
    }
}

// Get all documents from a subcollection
export async function getSubcollectionDocuments(
    collectionName,
    docId,
    subcollectionName
) {
    try {
        const subcollectionRef = collection(
            db,
            `${collectionName}/${docId}/${subcollectionName}`
        );
        const querySnapshot = await getDocs(subcollectionRef);
        return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    } catch (e) {
        console.error("Error getting subcollection documents: ", e);
        throw e;
    }
}

export const findCustomerByDetails = async (customerData) => {
    const queries = [];
    const customerCollection = collection(db, "customers");

    if (customerData.firstName) {
        queries.push(where("firstName", "==", customerData.firstName));
    }
    if (customerData.lastName) {
        queries.push(where("lastName", "==", customerData.lastName));
    }
    if (customerData.phone) {
        queries.push(where("phone", "==", customerData.phone));
    }
    if (customerData.email) {
        queries.push(where("email", "==", customerData.email));
    }

    let q;
    if (queries.length > 0) {
        q = query(customerCollection, ...queries);
    } else {
        // If no fields are filled, return an empty array
        return [];
    }

    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};
