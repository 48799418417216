<template>
    <Loading loader="bars" :isFullPage="true" :active="loading" :opacity="1" />

    <div v-if="user" id="main-app">
        <Navigation />
        <div class="container px-lg-5 py-3">
            <RouterView />
        </div>
    </div>
    <div v-else id="login-page">
        <LoginView />
    </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

import Navigation from "./components/Navigation.vue";
import LoginView from "./views/LoginView.vue";

import { useMainStore, useUserStore } from "@/store";
import { mapState } from "pinia";

export default {
    name: "App",
    components: {
        Loading,
        Navigation,
        LoginView
    },
    computed: {
        ...mapState(useUserStore, ["user"]),
        ...mapState(useMainStore, ["loading"])
    },
    mounted() {}
};
</script>
