<template>
    <div class="mb-3">
        <div style="background-color: #fd0; width: 100%">
            <div class="container">
                <nav class="navbar navbar-expand-lg">
                    <div class="container-fluid px-lg-5">
                        <RouterLink
                            :to="{ name: 'Case Management' }"
                            class="navbar-brand"
                        >
                            BAMS
                        </RouterLink>
                        <button
                            class="navbar-toggler"
                            type="button"
                            @click="toggleNavbar"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="isNavbarOpen"
                            aria-label="Toggle navigation"
                        >
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div
                            :class="[
                                'collapse',
                                'navbar-collapse',
                                { show: isNavbarOpen }
                            ]"
                            id="navbarSupportedContent"
                        >
                            <div class="d-flex flex-column flex-lg-row w-100">
                                <ul
                                    class="navbar-nav mb-2 mb-lg-0 d-flex flex-column flex-lg-row mx-auto"
                                >
                                    <li class="nav-item">
                                        <RouterLink
                                            :class="[
                                                'nav-link',
                                                isActive('Case Management') ||
                                                    isActive('Create Case') ||
                                                    isActive('View Case') ||
                                                    isActive('Edit Case')
                                            ]"
                                            :to="{ name: 'Case Management' }"
                                            @click="closeNavbar"
                                        >
                                            Cases
                                        </RouterLink>
                                    </li>
                                    <li class="nav-item">
                                        <RouterLink
                                            :class="[
                                                'nav-link',
                                                isActive(
                                                    'Customer Management'
                                                ) ||
                                                    isActive(
                                                        'Create Customer'
                                                    ) ||
                                                    isActive('View Customer') ||
                                                    isActive('Edit Customer')
                                            ]"
                                            :to="{
                                                name: 'Customer Management'
                                            }"
                                            @click="closeNavbar"
                                        >
                                            Customers
                                        </RouterLink>
                                    </li>
                                    <li class="nav-item">
                                        <RouterLink
                                            :class="[
                                                'nav-link',
                                                isActive('Fleet Management') ||
                                                    isActive(
                                                        'Create Fleet Car'
                                                    ) ||
                                                    isActive(
                                                        'View Fleet Car'
                                                    ) ||
                                                    isActive('Edit Fleet Car')
                                            ]"
                                            :to="{ name: 'Fleet Management' }"
                                            @click="closeNavbar"
                                        >
                                            Fleet
                                        </RouterLink>
                                    </li>
                                    <li class="nav-item">
                                        <RouterLink
                                            :class="[
                                                'nav-link',
                                                isActive('Vendor Management') ||
                                                    isActive('Create Vendor') ||
                                                    isActive('View Vendor') ||
                                                    isActive('Edit Vendor')
                                            ]"
                                            :to="{ name: 'Vendor Management' }"
                                            @click="closeNavbar"
                                        >
                                            Vendors
                                        </RouterLink>
                                    </li>
                                </ul>
                                <button
                                    @click="signUserOut"
                                    class="btn btn-link nav-link"
                                >
                                    <i
                                        class="bi-box-arrow-right"
                                        style="color: #000"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import { signOut, getAuth } from "firebase/auth";
import { useUserStore } from "@/store";
import { mapActions } from "pinia";

export default {
    name: "NavigationView",
    data() {
        return {
            isNavbarOpen: false
        };
    },
    methods: {
        ...mapActions(useUserStore, ["resetUserStore"]),
        signUserOut() {
            signOut(getAuth())
                .then(() => {
                    this.resetUserStore();
                    console.log("Sign out successful.");
                })
                .catch((error) => {
                    console.error("Sign out error: ", error);
                });
        },
        isActive(routeName) {
            return this.$route.name === routeName ? "active" : "";
        },
        toggleNavbar() {
            this.isNavbarOpen = !this.isNavbarOpen;
        },
        closeNavbar() {
            this.isNavbarOpen = false;
        }
    }
};
</script>

<style scoped>
.nav-link.active {
    font-weight: bold;
}

.navbar-toggler {
    border: none;
}
</style>
