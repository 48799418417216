<template>
    <div
        class="container-fluid min-vh-100 d-flex align-items-center justify-content-center"
        style="background-color: #ffd100"
    >
        <div class="col-12 col-md-8 col-lg-6">
            <div class="card shadow-sm rounded-4 bg-light">
                <div class="card-body p-5">
                    <h3 class="text-center mb-4 fw-bold">BAMS</h3>
                    <button
                        @click="signInWithGoogle"
                        class="btn btn-lg btn-primary w-100 mb-4 d-flex align-items-center justify-content-center"
                    >
                        <i class="bi-google me-2"></i>Sign in with Google
                    </button>
                    <div class="mb-4">
                        <h5 class="text-center">Or</h5>
                    </div>
                    <div v-if="!isRegistering">
                        <input
                            v-model="email"
                            type="email"
                            class="form-control mb-3"
                            placeholder="Email"
                        />
                        <input
                            v-model="password"
                            type="password"
                            class="form-control mb-3"
                            placeholder="Password"
                        />
                        <button
                            @click="signInWithEmail"
                            class="btn btn-lg btn-primary w-100 mb-3"
                        >
                            Sign in with Email
                        </button>
                        <div class="text-center">
                            <p>
                                Don't have an account?
                                <a
                                    @click="toggleRegistering"
                                    class="text-primary"
                                    style="cursor: pointer"
                                    >Register here</a
                                >
                            </p>
                        </div>
                    </div>
                    <div v-else>
                        <input
                            v-model="fullName"
                            type="text"
                            class="form-control mb-3"
                            placeholder="Full Name"
                        />
                        <input
                            v-model="email"
                            type="email"
                            class="form-control mb-3"
                            placeholder="Email"
                        />
                        <input
                            v-model="password"
                            type="password"
                            class="form-control mb-3"
                            placeholder="Password"
                        />
                        <button
                            @click="registerWithEmail"
                            class="btn btn-lg btn-primary w-100 mb-3"
                        >
                            Register with Email
                        </button>
                        <div class="text-center">
                            <p>
                                Already have an account?
                                <a
                                    @click="toggleRegistering"
                                    class="text-primary"
                                    style="cursor: pointer"
                                    >Sign in here</a
                                >
                            </p>
                        </div>
                    </div>
                    <div class="text-center mt-4">
                        <p
                            class="text-muted mb-0 d-flex align-items-center justify-content-center"
                        >
                            <img
                                src="@/assets/SlantedBoxLogo.svg"
                                alt="SlantedBox"
                                class="me-2"
                                style="height: 1rem"
                            />
                            <b>{{ new Date().getFullYear() }}</b>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    onAuthStateChanged,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    updateProfile
} from "firebase/auth";
import { collection, getDoc, doc, setDoc } from "firebase/firestore";
import db from "@/firebase/firebase-db.js";
import { useMainStore, useUserStore } from "@/store";
import { mapActions } from "pinia";

export default {
    name: "LoginView",
    data() {
        return {
            fullName: "",
            email: "",
            password: "",
            isRegistering: false
        };
    },
    mounted() {
        onAuthStateChanged(getAuth(), (user) => {
            if (user) {
                this.setUser(user);
            } else {
                console.log("User is signed out.");
            }
            this.setLoading(false);
        });
    },
    methods: {
        ...mapActions(useUserStore, ["setUser"]),
        ...mapActions(useMainStore, ["setLoading"]),
        async signInWithGoogle() {
            const provider = new GoogleAuthProvider();
            signInWithPopup(getAuth(), provider)
                .then(async (result) => {
                    const user = result.user;
                    const collectionRef = collection(db, "users");
                    const docRef = await getDoc(doc(collectionRef, user.uid));
                    if (!docRef.exists()) {
                        await setDoc(doc(collectionRef, user.uid), {
                            email: user.email,
                            name: user.displayName,
                            photoURL: user.photoURL
                        });
                    }

                    this.setUser(user);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async registerWithEmail() {
            const auth = getAuth();
            try {
                const userCredential = await createUserWithEmailAndPassword(
                    auth,
                    this.email,
                    this.password
                );
                const user = userCredential.user;
                await updateProfile(user, {
                    displayName: this.fullName
                });
                const collectionRef = collection(db, "users");
                await setDoc(doc(collectionRef, user.uid), {
                    email: user.email,
                    name: user.displayName,
                    photoURL: user.photoURL || ""
                });
                this.setUser(user);
            } catch (error) {
                if (error.code === "auth/email-already-in-use") {
                    alert("Email is already in use. Please sign in instead.");
                } else {
                    console.log(error);
                }
            }
        },
        async signInWithEmail() {
            const auth = getAuth();
            try {
                const userCredential = await signInWithEmailAndPassword(
                    auth,
                    this.email,
                    this.password
                );
                const user = userCredential.user;
                this.setUser(user);
            } catch (error) {
                console.log(error.code);
                if (error.code === "auth/invalid-credential") {
                    alert(
                        "Login credentials are incorrect or user is not registered. Please register first."
                    );
                } else {
                    console.log(error);
                }
            }
        },
        toggleRegistering() {
            this.isRegistering = !this.isRegistering;
        }
    }
};
</script>

<style scoped>
.form-control {
    height: calc(3.5rem + 2px);
    font-size: 1.25rem;
}
</style>
