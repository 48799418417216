<template>
    <div :class="['alert', alertClass]" role="alert">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        primary: {
            type: Boolean,
            default: false
        },
        secondary: {
            type: Boolean,
            default: false
        },
        success: {
            type: Boolean,
            default: false
        },
        danger: {
            type: Boolean,
            default: false
        },
        warning: {
            type: Boolean,
            default: false
        },
        info: {
            type: Boolean,
            default: false
        },
        light: {
            type: Boolean,
            default: false
        },
        dark: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        alertType() {
            if (this.primary) return "primary";
            if (this.secondary) return "secondary";
            if (this.success) return "success";
            if (this.danger) return "danger";
            if (this.warning) return "warning";
            if (this.info) return "info";
            if (this.light) return "light";
            if (this.dark) return "dark";
            return "primary"; // Default type if none provided
        },
        alertClass() {
            return `alert-${this.alertType}`;
        }
    }
};
</script>
