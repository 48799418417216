import { defineStore } from "pinia";
import { getDocuments, deleteDocument } from "./firebase/db";

export const useMainStore = defineStore("main", {
    state: () => ({
        loading: true,
        cases: [],
        customers: [],
        fleetCars: [], // Initialized to an empty array
        recovery_agents: [],
        storage_locations: [],
        repairers: [],
        assessors: [],
        inspectors: []
    }),

    getters: {},

    actions: {
        setLoading(loading) {
            this.loading = loading;
        },
        // Cases
        setCases(cases) {
            this.cases = cases;
        },
        async fetchCases() {
            const cases = await getDocuments("cases");
            this.setCases(cases);
        },
        // Customers
        setCustomers(customers) {
            this.customers = customers;
        },
        appendCustomer(customer) {
            const index = this.customers.findIndex((c) => c.id === customer.id);
            if (index === -1) {
                console.log("Adding customer");
                this.customers.push(customer);
            }
        },
        async fetchCustomers() {
            const customers = await getDocuments("customers");
            this.setCustomers(customers);
        },
        // Fleet Cars
        setFleetCars(fleetCars) {
            this.fleetCars = fleetCars;
        },
        appendFleetCar(fleetCar) {
            if (!Array.isArray(this.fleetCars)) {
                this.fleetCars = [];
            }
            const index = this.fleetCars.findIndex((c) => c.id === fleetCar.id);
            if (index === -1) {
                console.log("Adding fleet car");
                this.fleetCars.push(fleetCar);
            }
        },
        async fetchFleet() {
            const fleetCars = await getDocuments("fleet");
            this.setFleetCars(fleetCars);
        },
        // Recovery Agents
        setRecoveryAgents(recovery_agents) {
            this.recovery_agents = recovery_agents;
        },
        appendRecoveryAgent(recoveryAgent) {
            if (!Array.isArray(this.recovery_agents)) {
                this.recovery_agents = [];
            }
            const index = this.recovery_agents.findIndex(
                (c) => c.id === recoveryAgent.id
            );
            if (index === -1) {
                console.log("Adding recovery agent");
                this.recovery_agents.push(recoveryAgent);
            }
        },
        async fetchRecoveryAgents() {
            const recovery_agents = await getDocuments("recovery_agents");
            this.setRecoveryAgents(recovery_agents);
        },
        // Storage Locations
        setStorageLocations(storage_locations) {
            this.storage_locations = storage_locations;
        },
        appendStorageLocation(storageLocation) {
            if (!Array.isArray(this.storage_locations)) {
                this.storage_locations = [];
            }
            const index = this.storage_locations.findIndex(
                (c) => c.id === storageLocation.id
            );
            if (index === -1) {
                console.log("Adding storage location");
                this.storage_locations.push(storageLocation);
            }
        },
        async fetchStorageLocations() {
            const storage_locations = await getDocuments("storage_locations");
            this.setStorageLocations(storage_locations);
        },
        // Repairers
        setRepairers(repairers) {
            this.repairers = repairers;
        },
        appendRepairer(repairer) {
            if (!Array.isArray(this.repairers)) {
                this.repairers = [];
            }
            const index = this.repairers.findIndex((c) => c.id === repairer.id);
            if (index === -1) {
                console.log("Adding repairer");
                this.repairers.push(repairer);
            }
        },
        async fetchRepairers() {
            const repairers = await getDocuments("repairers");
            this.setRepairers(repairers);
        },
        // Assessors
        setAssessors(assessors) {
            this.assessors = assessors;
        },
        async fetchAssessors() {
            const assessors = await getDocuments("assessors");
            this.setAssessors(assessors);
        },
        // Inspectors
        setInspectors(inspectors) {
            this.inspectors = inspectors;
        },
        async fetchInspectors() {
            const inspectors = await getDocuments("inspectors");
            this.setInspectors(inspectors);
        },
        async deleteVendor({ type, id }) {
            try {
                await deleteDocument(type, id);
                this[type] = this[type].filter((item) => item.id !== id);
            } catch (error) {
                console.error(`Error deleting ${type.slice(0, -1)}: `, error);
            }
        }
    }
});

const defaultUserState = () => {
    return {
        user: null
    };
};

export const useUserStore = defineStore("user", {
    state: () => defaultUserState(),

    getters: {
        isLoggedIn: (state) => !!state.user
    },

    actions: {
        resetUserStore() {
            Object.assign(this, defaultUserState());
        },
        setUser(user) {
            this.user = user;
        }
    }
});
