// Import the vue app and pinia store
import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";

// Import the router
import router from "@/router/router";
import Notice from "./components/common/NoticeBar.vue";

// Bootstrap
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";

// Toaster
import Toaster from "@meforma/vue-toaster";

// Create the app
const pinia = createPinia();
const app = createApp(App);

// Register the router and pinia store with the app
app.use(Toaster, {
    position: "top"
});
app.use(router);
app.use(pinia);
app.component("Notice", Notice);
app.mount("#app");
