// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
    getFirestore,
    initializeFirestore,
    persistentLocalCache,
    persistentMultipleTabManager
} from "firebase/firestore";

import {
    getFunctions
    // connectFunctionsEmulator
} from "firebase/functions";

import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyD3gcR_axknG2LF8ir4zjuQCwurNuJ5nqg",
    authDomain: "ni-accident-management.firebaseapp.com",
    projectId: "ni-accident-management",
    storageBucket: "ni-accident-management.appspot.com",
    messagingSenderId: "838628366652",
    appId: "1:838628366652:web:1590fa84f0733a8459e639"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
initializeFirestore(app, {
    localCache: persistentLocalCache({
        tabManager: persistentMultipleTabManager()
    }),
    ignoreUndefinedProperties: true
});

initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(
        "6Lf21CcqAAAAAMjTU0gGsxmqaCG4w2ctup76tCKn"
    ),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true
});

const functions = getFunctions(app, "europe-west1");
// connectFunctionsEmulator(functions, "127.0.0.1", 5001);

export { functions };

const db = getFirestore();
export default db;
