// Import the router
import { createRouter, createWebHistory } from "vue-router";

// Define the routes
const routes = [
    {
        path: "/",
        component: () => import("@/views/HomeView.vue"),
        name: "Home"
    },
    // CASES
    {
        path: "/cases",
        component: () => import("@/views/cases/CaseManagement.vue"),
        name: "Case Management"
    },
    {
        path: "/cases/create",
        component: () => import("@/views/cases/CreateCaseView.vue"),
        name: "Create Case"
    },
    {
        path: "/cases/:id",
        component: () => import("@/views/cases/ViewCaseView.vue"),
        name: "View Case"
    },
    {
        path: "/cases/:id/edit",
        component: () => import("@/views/cases/EditCaseView.vue"),
        name: "Edit Case"
    },
    // CUSTOMERS
    {
        path: "/customers",
        component: () => import("@/views/customers/CustomerManagement.vue"),
        name: "Customer Management"
    },
    {
        path: "/customers/create",
        component: () => import("@/views/customers/CreateCustomerView.vue"),
        name: "Create Customer"
    },
    {
        path: "/customers/:id",
        component: () => import("@/views/customers/ViewCustomerView.vue"),
        name: "View Customer"
    },
    {
        path: "/customers/:id/edit",
        component: () => import("@/views/customers/EditCustomerView.vue"),
        name: "Edit Customer"
    },
    {
        path: "/components",
        component: () => import("@/views/ComponentsView.vue"),
        name: "Components View"
    },
    // FLEET
    {
        path: "/fleet",
        component: () => import("@/views/fleet/FleetManagement.vue"),
        name: "Fleet Management"
    },
    {
        path: "/fleet/create",
        component: () => import("@/views/fleet/CreateFleetCarView.vue"),
        name: "Create Fleet Car"
    },
    {
        path: "/fleet/:id",
        component: () => import("@/views/fleet/ViewFleetCarView.vue"),
        name: "View Fleet Car"
    },
    {
        path: "/fleet/:id/edit",
        component: () => import("@/views/fleet/EditFleetCarView.vue"),
        name: "Edit Fleet Car"
    },
    // RECOVERY AGENTS
    {
        path: "/recovery-agents",
        component: () =>
            import("@/views/recovery-agents/RecoveryAgentManagement.vue"),
        name: "Recovery Agent Management"
    },
    {
        path: "/recovery-agents/create",
        component: () =>
            import("@/views/recovery-agents/CreateRecoveryAgentView.vue"),
        name: "Create Recovery Agent"
    },
    {
        path: "/recovery-agents/:id",
        component: () =>
            import("@/views/recovery-agents/ViewRecoveryAgentView.vue"),
        name: "View Recovery Agent"
    },
    {
        path: "/recovery-agents/:id/edit",
        component: () =>
            import("@/views/recovery-agents/EditRecoveryAgentView.vue"),
        name: "Edit Recovery Agent"
    },
    // STORAGE LOCATIONS
    {
        path: "/storage-locations",
        component: () =>
            import("@/views/storage-locations/StorageLocationManagement.vue"),
        name: "Storage Location Management"
    },
    {
        path: "/storage-locations/create",
        component: () =>
            import("@/views/storage-locations/CreateStorageLocationView.vue"),
        name: "Create Storage Location"
    },
    {
        path: "/storage-locations/:id",
        component: () =>
            import("@/views/storage-locations/ViewStorageLocationView.vue"),
        name: "View Storage Location"
    },
    {
        path: "/storage-locations/:id/edit",
        component: () =>
            import("@/views/storage-locations/EditStorageLocationView.vue"),
        name: "Edit Storage Location"
    },
    // REPAIRERS
    {
        path: "/repairers",
        component: () => import("@/views/repairers/RepairerManagement.vue"),
        name: "Repairer Management"
    },
    {
        path: "/repairers/create",
        component: () => import("@/views/repairers/CreateRepairerView.vue"),
        name: "Create Repairer"
    },
    {
        path: "/repairers/:id",
        component: () => import("@/views/repairers/ViewRepairerView.vue"),
        name: "View Repairer"
    },
    {
        path: "/repairers/:id/edit",
        component: () => import("@/views/repairers/EditRepairerView.vue"),
        name: "Edit Repairer"
    },
    // Vendors
    {
        path: "/vendors",
        component: () => import("@/views/vendors/VendorManagement.vue"),
        name: "Vendor Management"
    },
    {
        path: "/vendors/:type?/create",
        component: () => import("@/views/vendors/CreateVendorView.vue"),
        name: "Create Vendor",
        props: true
    },
    {
        path: "/vendors/:type?/:id",
        component: () => import("@/views/vendors/ViewVendorView.vue"),
        name: "View Vendor"
    },
    {
        path: "/vendors/:type?/:id/edit",
        component: () => import("@/views/vendors/EditVendorView.vue"),
        name: "Edit Vendor",
        props: true
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach(async (to) => {
    // Extract the first segment of the path, e.g., "/storage-locations/create" -> "storage-locations"
    let title = to.path.split("/")[1];

    // If there's a valid segment, format it with the first letter capitalized
    if (title) {
        title = title.charAt(0).toUpperCase() + title.slice(1);

        // For routes with multiple segments, you can extend logic to handle more descriptive titles
        if (to.path.split("/").length > 2) {
            const subTitle = to.path.split("/")[2];
            title += ` ${subTitle.charAt(0).toUpperCase() + subTitle.slice(1)}`;
        }

        document.title = title;
    } else {
        // Set the default title if no valid title can be derived from the path
        document.title = "Accident Management";
    }
});

export default router;
